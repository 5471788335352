import {
  APIError,
  AuthError,
  ConflictError,
  EntityError,
  NotFoundError
} from "@/services/error_service";
import {
  CategoryModel,
  CategoryModelRequest,
  CategoryRequestOptions
} from "@/models/category";
import axios, { AxiosResponse } from "axios";

import { APIResponse } from "@/models/api_res";
import Vue from "vue";

axios.defaults.withCredentials = true;
/**
 * Creates a new Category Service

 * @example
 * const categoryService = new CategoryService();
 * categoryService.getcategory();
 */

export default class CategoryService {
  /**
   * Get paginated list of category
   * Filtered by parameters
   * @param {object} optionsObject optional:
   *  {
   *    @param {number} page page of results to return, optional
   *    @param {number} offset number of results to return, optional (default = 10)
   *    @param {string} bu The business unit to filter by, optional (default = 'bmh')
   *    @param {string} q Query term for keyword search
   *    @param {boolean} leaf Filters the resulting categories to only include leaf categories.
   *    @param {boolean} empty Filters the resulting categories to only include empty categories.
   *    @param {boolean} hidden Filters the resulting categories to only include empty categories.
   *    @param {boolean} roots Filters the resulting categories to only include root (product line)
   *    @param {string} path Filter the resulting categories by the URL
   *    @param {boolean} full Retrieve the full category data model for each result.
   *    Note that this is an intensive and unoptimized operation. This option should only be used in conjunction with the path query parameter to simulate making a call to GET /categories/{id} but with the
   *    category path rather than ID.
   *
   * }
   *   * NOTES:
   *    currently bu is never used, so it is not included in logic
   * leaf, empty, roots does not require a value to be present. If only the key is provided, then the value of
   *                          the parameter will be taken to be true
   *
   * @returns {APIResponse} - response object
   */
  public async getCategories(
    optionsObject?: CategoryRequestOptions
  ): Promise<APIResponse> {
    let categories;
    let url = process.env.VUE_APP_API_URL_NODE + "/categories";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.page) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "page=" + optionsObject.page;
      }
      if (optionsObject.offset) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "per_page=" + optionsObject.offset;
      }
      if (optionsObject.q) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "q=" + optionsObject.q;
      }
      if (optionsObject.leaf) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "leaf";
      }
      if (optionsObject.empty) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "empty";
      }
      if (optionsObject.hidden) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "hidden";
      }
      if (optionsObject.path) {
        if (optionsObject.path.charAt(0) !== "/") {
          optionsObject.path = "/" + optionsObject.path;
        }
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "path=" + optionsObject.path;
      }
      if (optionsObject.full) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "full=" + optionsObject.full;
      }
      if (optionsObject.roots) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "roots";
      }

      if (optionsObject.bu) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "bu=" + optionsObject.bu;
      }
    }
    try {
      const res = await axios.get(url);
      categories = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
    return categories;
  }

  /**
   * POST new category to server
   * @param {Category} request - new category data object
   * @returns {Category} - newly created category object
   */
  public async createNewCategory(
    request: CategoryModelRequest
  ): Promise<CategoryModel> {
    let category;

    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      const res = await axios.post(
        process.env.VUE_APP_API_URL_NODE + "/categories",
        body,
        options
      );
      category = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new APIError(err.response.data.message || "Something went wrong. Please try again.");
      }
      throw new APIError("Something went wrong. Please try again.");
    }
    return category;
  }

  /**
   * Get selected single category
   * @param {number} id - category id
   * @returns {Category} - selected category's data object
   */
  public async getSingleCategory(id: number): Promise<CategoryModel> {
    let category: CategoryModel;

    const options = {
      withCredentials: true
    };
    try {
      const res = await axios.get(
        // process.env.VUE_APP_API_URL + "/categories/" + id,
        process.env.VUE_APP_API_URL_NODE + "/categories/" + id,
        options
      );
      category = res.data;
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested category not found.");
      } else {
        throw new APIError(
          "Something went wrong. Please try again.",
          err.status
        );
      }
    }
    return category;
  }

  /**
   * Save current category
   * @param {CategoryModelRequest} request - category request data
   * @param {number} id - category id
   * @returns {Promise<void>}
   */
  public async saveCategory(
    request: CategoryModelRequest,
    id: number
  ): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const body = JSON.stringify(request);
    try {
      await axios.patch(
        // request.display_name
        //   ? process.env.VUE_APP_API_URL_NODE + "/categories/" + id
        //   : process.env.VUE_APP_API_URL + "/categories/" + id,
        process.env.VUE_APP_API_URL_NODE + "/categories/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested category not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Category already exists.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Move the current category in the hierarchy by changing its parent category
   * @param {number} id - category id
   * @param {number} parentid - new parent id
   * If null or 0 is passed for the parent id, then the category will be made a product line.
   * @param {Category} request - category request data
   * @returns {Promise<void>}
   */
  public async moveCategory(parentid: number, id: number): Promise<void> {
    const options = {
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    };
    const request = { new_parent: parentid };
    const body = JSON.stringify(request);
    try {
      await axios.put(
        // process.env.VUE_APP_API_URL + "/categories/" + id,
        process.env.VUE_APP_API_URL_NODE + "/categories/" + id,
        body,
        options
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested category not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Category already exists.");
      } else if (err.response && err.response.status === 422) {
        throw new EntityError("Error: Action is not allowed.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Delete category
   * @param {number} id - category id
   * @returns {Promise<void>}
   * A category can only be deleted if it is a leaf node and there are no products attached to it. If either of these conditions are broken, a 422 will be returned.
   */
  public async deleteCategory(id: number): Promise<void> {
    try {
      const options = {
        withCredentials: true
      };
      return await axios.delete(
        // process.env.VUE_APP_API_URL + "/categories/" + id
        process.env.VUE_APP_API_URL_NODE + "/categories/" + id
      );
    } catch (err) {
      if (err.response && err.response.status === 401) {
        throw new AuthError(err.response);
      }
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested category not found.");
      } else if (err.response && err.response.status === 409) {
        throw new ConflictError("Error: Category already exists.");
      } else if (err.response && err.response.status === 422) {
        throw new EntityError("Error: Action is not allowed.");
      } else {
        throw new APIError("Something went wrong. Please try again.");
      }
    }
  }

  /**
   * Get selected category products in csv format
   * @param {number} id - category id
   * @returns
   */
  public async getProductCsv(id: number): Promise<any> {
    let csv: any;
    try {
      const options = {
        withCredentials: true
      };
      const res = await axios.get(
        process.env.VUE_APP_API_URL_NODE + "/export/" + id,
        options
      );
      csv = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError("Error: Requested products not found.");
      } else {
        throw new APIError(err.message, err.status);
      }
    }
    return csv;
  }
}
